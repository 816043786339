<template>
  <journal-goods-list
    :id="id"
    :show.sync="isShow"
    :headers="headers"
    :title="title"
    :wares="items"
  >
    <template #header>
      <goods-list-header
        :declaration="declaration"
        :wares="items"
      />
    </template>
  </journal-goods-list>
</template>
<script>
import JournalGoodsList from "@/components/journal/journal-goods-list.vue";
import GoodsListHeader from "@/components/rs/journal/goods-list-header.vue";
import {getCostString} from "@/helpers/text";
import JournalGoodsListMixin from "@/mixins/journal-goods-list.mixin";


export default {
  components:{
    GoodsListHeader,
    JournalGoodsList
  },
  mixins:[JournalGoodsListMixin],
  data(){
    return{
      declaration:{},
      items:[],
      headers:[
        {
          text:"№",
          value:"index"
        },
        {
          text:"Код товара",
          value:"tn_ved"
        },
        {
          text:"Категория",
          value:"transport_category"
        },
        {
          text:"Наименование товара",
          value:"title"
        },
        {
          text:"Стоимость",
          value:"cost"
        },
        {
          text:"Дата выпуска",
          value:"release_date"
        }
      ],
    }
  },
  methods:{
    setItems(wares){
      this.items = wares.map((item, idx) => {
        const {
          id,
          tn_ved,
          transport_category,
          title,
          release_date,
          invoice_cost,
          invoice_currency_letter,
        } = item
        const cost = getCostString(invoice_cost, invoice_currency_letter)
        return {
          index:idx + 1,
          id,
          tn_ved,
          transport_category,
          title,
          release_date,
          cost
        }
      })
    },
    fetchDocument(){
      this.$store.dispatch(`rs/getDocumentInfo`, this.id).then(res => {
        this.declaration = res.data
        this.setItems(this.declaration.wares)
      }).catch(this.errorHandler)
    },
  }
}
</script>
