import {downloadXml, downloadFile, getFilenameFromContentDisposition} from "@/helpers/documents";

const actions = [
  {
    icon: "mdi-file-pdf-box",
    action: "downloadPdf",
    name: "Скачать PDF"
  },
  {
    icon: "mdi-content-copy",
    name: "Копировать документ",
    action: "copyDocument",
    component: () => import('@/components/rs/journal/copy-document.vue')
  },
  {
    icon: "mdi-file-document-edit-outline",
    name: "Скорректировать",
    action: "adjustDocument",
  },
  {
    icon: "mdi-file-document-plus",
    action: "orderPdf",
    name: "Формирование заявки в РС"
  },
  {
    icon: "mdi-delete",
    name: "Удалить документ",
    action: "deleteDocument",
  },
  {
    icon: "mdi-account",
    name: "Изменить исполнителя",
    action: "changePerformer",
    divider: true,
    component: () => import('@/components/documents/change-performer.vue')
  },
  null,
  {
    icon: "mdi-tray-arrow-down",
    name: "Скачать xml",
    divider: true,
    action: "downloadXml"
  },
  {
    icon: "mdi-tray-arrow-down",
    name: "Скачать xml ПТД",
    action: "downloadPtdXml"
  },
  {
    icon: "mdi-tray-arrow-down",
    name: "Скачать документы заявки",
    action: "downloadDocumentsArchive"
  },
  null,
  {
    icon: "mdi-pause",
    name: "Отложить",
    action: "changeDeferredStatus"
  },
  {
    icon: "mdi-send",
    name: "Уведомить клиента",
    action: "notifyClient",
    divider: true,
    component: () => import('@/components/documents/notify-client.vue')
  },
  {
    icon: "mdi-send",
    name: "Уведомить в ЛК",
    action: "notifyClientLK",
    divider: true,
    component: () => import('@/components/documents/notifyclientlk.vue')
  },
  {
    icon: "mdi-marker-check",
    name: "Завершить оформление",
    action: "attachPermit",
    divider: true,
    component: () => import('@/components/documents/attach-permit.vue')
  }
]

const methods = {
  adjustDocument({id}) {
    this.$store.dispatch('rs/createCorrection', {id}).then(res => {
      this.$success(`Создана корректировка - ${res.data}`)
      this.$store.dispatch('journal/fetchItems')
    }).catch(() => this.$error())
  },
  downloadPtdXml({id}) {
    this.$store.dispatch("rs/getPtdXml", id).then((res) => {
      downloadXml(res.data, getFilenameFromContentDisposition(res))
    }).catch(() => this.$info("Документ отсутствует"));
  },
  downloadXml({id}) {
    this.$store.dispatch("rs/getXml", id).then((res) => {
      downloadXml(res.data, getFilenameFromContentDisposition(res))
    });
  },
  downloadPdf({id}) {
    this.$store.dispatch('rs/downloadPdf', id).then((res) => {
      const name = getFilenameFromContentDisposition(res)
      downloadFile({data: res.data, type: 'application/pdf', name})
    }).catch(() => this.$error())
  },
  orderPdf({id}) {
    this.$store.dispatch('rs/orderPdf', id).then((res) => {
      const name = getFilenameFromContentDisposition(res)
      downloadFile({data: res.data, type: 'application/pdf', name})
    }).catch(() => this.$error())
  },
  copyDocument({id, actionId}) {
    this.selectedId = id
    this.activeAction = this.getActionIndex(actionId)
    this.showComponent = true
  },
  deleteDocument({id, item}) {
    const value = !item.is_trash
    this.$store.dispatch('rs/toTrash', {id, value}).then(() => {
      this.$success("Документ удален")
      this.$store.dispatch('journal/fetchItems')
    }).catch(() => this.$error())
  },
  changePerformer({id, actionId}) {
    this.selectedId = id
    this.activeAction = this.getActionIndex(actionId)
    this.showComponent = true
  },
  notifyClient({id, actionId}) {
    this.selectedId = id
    this.activeAction = this.getActionIndex(actionId)
    this.showComponent = true
  },
  notifyClientLK({id, actionId}) {
    this.selectedId = id
    this.activeAction = this.getActionIndex(actionId)
    this.showComponent = true
  },
  attachPermit({id, actionId}){
    this.selectedId = id
    this.activeAction = this.getActionIndex(actionId)
    this.showComponent = true
  },
  downloadDocumentsArchive({id}) {
    this.$store.dispatch("rs/getDocumentArchive", id)
  },
  changeDeferredStatus({id, item}) {
    this.$store.dispatch('rs/changeDeferredStatus', {
      documentId: id,
      status: !item.is_deferred
    }).then(() => {
      this.$success("Статус изменен");
      this.$store.commit('experiment/SET_USER_FREE', 0)
      this.$store.dispatch('journal/fetchItems')
    }).catch(() => this.$error())
  },
  // ДЛЯ ОБРАБОТКИ КОМПОНЕНТА
  getActionIndex(actionId) {
    const action = this.actions.find(i => i?.action === actionId)
    return this.actions.indexOf(action)
  }
}

export {
  methods, actions
}
