<template>
  <v-row class="teal lighten-2 pa-2 rounded mb-0 white--text">
    <v-col cols="2">
      Всего товаров: {{ wares.length }}
    </v-col>
    <v-col
      cols="6"
    >
      Инвойс {{ invoice }}
    </v-col>
  </v-row>
</template>
<script>
import {getDocumentString} from "@/helpers/text";

export default {
  props:{
    wares:{
      required:true,
      type:Array
    },
    declaration:{
      required:true,
      type:Object
    },
  },
  computed:{
    invoice(){
      const {doc_date, doc_number} = this.declaration?.presented_documents?.find(i => i.doc_code === '04021') ?? {}
      return getDocumentString(doc_date, doc_number)
    },
  }
}
</script>
